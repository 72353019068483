<template>
  <div class="w100 h100 index">
    <!-- <SlideLogin></SlideLogin> -->
    <van-loading type="spinner" color="#fff" v-show="isLoading" />
    <div v-show="!isLoading">
      <div class="slide-login">
        <div class="relative wrapper">
          <div class="py20 tc">
            <img src="@/assets/img/icon986.png" class="w50" />
          </div>
          <div
            id="aliValidate"
            class="aliValidate"
            @click="(e) => e.stopPropagation()"
          ></div>
          <div class="closeBtn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    // 阿里人机验证
    initAliValiadte() {
      let vue = this;
      let userAgent = navigator.userAgent;
      let isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1; //g
      let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // 实例化ic对象
      AWSC.use("ic", function(state, module) {
        // 初始化
        window.ic = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: "FFFF0N00000000009C35",
          // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: "ic_message_h5",
          // 声明智能验证需要渲染的目标元素ID。
          renderTo: "aliValidate",
          // 该配置项为测试项 在仅用来测试验证码不同状态时使用。上线时请将其删除. 智能验证test配置项有4种不同的值对应不同的验证码状态，具体请参考文中参数定义说明部分。
          // test: module.TEST_PASS, // 测试智能验证通过
          // test: module.TEST_BLOCK, // 测试智能验证失败
          // test: module.TEST_NC_PASS, // 唤醒滑动验证并验证通过
          // test: module.TEST_NC_BLOCK, // 唤醒滑动验证并验证失败
          // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: function(params) {
            //成功的回调
            // 返回的二次验证参数 合并到验证通过之后的逻辑 参数中回传服务器
            params = Object.assign(params, {
              scene: "ic_message_h5",
            });
            console.log(
              "params===",
              params,
              "isAndroid:",
              isAndroid,
              "isIOS:",
              isIOS,
              "window.webkit:::",
              window.webkit
            );
            if (isAndroid) {
              //这个是安卓操作系统
              try {
                window.AndroidJs.getSlideData(JSON.stringify(params));
              } catch (err) {
                console.log(
                  "error:::执行window.AndroidJs.getSlideData(JSON.stringify(params));不成功"
                );
              }
            }
            if (isIOS) {
              //这个是ios操作系统
              try {
                window.webkit.messageHandlers.getSlideData.postMessage(params);
              } catch (err) {
                console.log(
                  "error:::执行window.webkit.messageHandlers.getSlideData.postMessage(params)不成功"
                );
              }
            }
          },
          // 验证失败时触发该回调参数
          fail: function(failCode) {
            console.log("ali验证失败", failCode);
          },
          // 验证码加载异常时触发该回调参数
          error: function(errorCode) {
            console.log("ali组件错误", errorCode);
          },
        });
      });
    },
  },
  mounted() {
    this.initAliValiadte();

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    let vue = this;
    let userAgent = navigator.userAgent;
    let isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1; //g
    let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    $(".closeBtn").click(function() {
      console.log("closing", window.webkit);
      if (isIOS) {
        try {
          window.webkit.messageHandlers.getCloseData.postMessage(null);
        } catch (err) {
          console.log(
            "error:::执行window.webkit.messageHandlers.closeWebview.postMessage()不成功"
          );
        }
      } else if (isAndroid) {
        try {
          window.AndroidJs.close();
        } catch (err) {
          console.log(
            "error:::执行window.webkit.messageHandlers.closeWebview.postMessage()不成功"
          );
        }
      }
    });
  },
};
</script>

<style lang="less" scoped>
.van-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
// ali验证样式修改
/deep/.sm-btn-wrapper {
  background: #fff;
}
.index {
  background: rgba(0, 0, 0, 0.2);
}
.aliValidate {
  // top: 50%;
  // left: 10%;
  // width: 80%;
  height: 50px;
}
// .closeBtn {
//   position: absolute;
//   left: 50%;
//   bottom: 20%;
//   margin-top: 20px;
//   width: 30px;
//   height: 30px;
//   background: url(../assets/img/close.png) no-repeat center;
//   background-size: 100% 100%;
// }
.closeBtn {
  position: absolute;
  left: 50%;
  bottom: -60px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  background: url(../assets/img/close.png) no-repeat center;
  background-size: 100% 100%;
}
</style>
